import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchLeadsStart = () => {
  return {
    type: actionTypes.FETCH_LEADS_START,
  };
};

const fetchLeadsSuccess = (leadsData) => {
  return {
    type: actionTypes.FETCH_LEADS_SUCCESS,
    payload: leadsData,
  };
};

const fetchLeadsFailed = (error) => {
  return {
    type: actionTypes.FETCH_LEADS_FAIL,
    error: error,
  };
};

export const fetchLeads = (page, pageSize) => async (dispatch) => {
  dispatch(fetchLeadsStart());

  try {
    const { data } = await axios.get(`/lead?page=${page}&pageSize=${pageSize}`);

    dispatch(fetchLeadsSuccess(data.data));
  } catch (err) {
    dispatch(fetchLeadsFailed(err));
  }
};

export const filterLeads =
  (page, pageSize, searchType, firstSearchValue, secondSearchValue) => async (dispatch) => {
    dispatch(fetchLeadsStart());
    try {
      const { data } = await axios.get(
        `/lead/?searchType=${searchType}&firstSearchValue=${firstSearchValue}&secondSearchValue=${secondSearchValue}&page=${page}&pageSize=${pageSize}`,
      );
      dispatch(fetchLeadsSuccess(data.data));
    } catch (err) {
      dispatch(fetchLeadsFailed(err));
    }
  };

const deleteLeadFailed = (error) => {
  return { type: actionTypes.DELETE_LEAD_FAILED, error };
};

export const deleteLead = (leadId) => async (dispatch) => {
  try {
    await axios.delete('/lead/' + leadId);
    dispatch(fetchLeads());
  } catch (err) {
    dispatch(deleteLeadFailed(err));
  }
};
