import * as actionTypes from '../actions/actionTypes';

const initialState = {
  show: false,
  message: '',
  messageType: 'success',
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOAST:
      return {
        ...state,
        show: true,
        message: action.message,
        messageType: action.messageType,
      };
    case actionTypes.REMOVE_TOAST:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default toastReducer;
