/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Minus } from '../../assets/svg/down-arrow-svgrepo-com.svg';
import { ReactComponent as Equal } from '../../assets/svg/equal-svgrepo-com.svg';
import { ReactComponent as Plus } from '../../assets/svg/up-arrow-svgrepo-com.svg';
import LeadDetail from '../../components/LeadDetail/LeadDetail';
import LeadState from '../../components/LeadState/LeadState';
import CSVDownloader from '../../components/UI/CSVDownloader/CSVDownloader';
import Button from '../../components/UI/CustomButtons/Button';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import GridContainer from '../../components/UI/Grid/GridContainer';
import GridItem from '../../components/UI/Grid/GridItem';
import Pagination from '../../components/UI/Pagination/Pagination';
import DatePickerInput from '../../components/UI/SpecialInput/DatePickerInput/DatePickerInput';
import SpecialInput from '../../components/UI/SpecialInput/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import TableList from '../../components/UI/TableList/TableList';
import * as actions from '../../store/actions';
import { parseToCurrency } from '../../utils/utility';
import classes from './Leads.module.css';

const PAGE_LIMIT = 12;
const statuses = [
  { value: '-', label: 'Todas' },
  { value: 'SIMULATED_CREDIT', label: 'Crédito simulado' },
  { value: 'ENTERED_PERSONAL_DATA', label: 'Datos personales ingresados' },
  { value: 'ENTERED_REST_PERSONAL', label: 'Ingreso al formulario de pagos' },
  { value: 'COMPLETED_REJECTED', label: 'Completado rechazado' },
  { value: 'COMPLETED_APPROVED', label: 'Completado aprobado' },
  { value: 'DECLARED_CLEARING', label: 'Cliente Declaró Clearing' },
];

function Leads({
  allLeads,
  loading,
  entities,
  onFetchLeads,
  onFetchEntities,
  onDeleteLead,
  onFetchFilteredLeads,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  // EL CÓDIGO COMENTADO ES RELATIVO A ENTITIES. POR EL MOMENTO QUEDA ASÍ PERO EN UN FUTURO PUEDE QUE SE AGREGUE NUEVAMENTE
  // const [searchableEntities, setSearchableEntities] = useState([]);
  // const [selectedEntity, setSelectedEntity] = useState({ value: 0, label: 'Todas' });
  const [selectedStatus, setSelectedStatus] = useState(statuses[0]);
  const [searchedValue, setSearchedValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [leadToDelete, setLeadToDelete] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);

  // const handleEntities = async () => {
  //   const newEntities = entities?.map((entity) => {
  //     return {
  //       value: entity.entityId,
  //       label: entity.name,
  //     };
  //   });
  //   newEntities?.unshift({ value: 0, label: 'Todas' });
  //   setSearchableEntities(newEntities);
  // };

  const handlePageClick = ({ currentPage }) => handleNextPage(currentPage);

  const resetFilters = (actualFilter) => {
    if (actualFilter !== 'date') {
      setStartDate(null);
      setEndDate(null);
    }
    if (actualFilter !== 'text') {
      setSearchedValue('');
    }
    if (actualFilter !== 'state') {
      setSelectedStatus(statuses[0]);
    }
  };

  // const changeState = (state) => {
  //   const statusFound = statuses.find((status) => status.value === state);
  //   return statusFound?.label || '-';
  // };

  const parseToDateTime = (date) => {
    // dd/mm/yy hh:mm
    return new Date(date).toLocaleDateString('es-UY', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getEntityNameById = (id) => {
    if (entities) {
      return entities?.find((entity) => entity.entityId === id).name;
    }
  };

  // const selectEntityHandler = (selectedEntity) => {
  //   const entity = searchableEntities.find((e) => e.value === selectedEntity);
  //   setSelectedEntity(entity);
  // };

  const selectStatusHandler = (selectedStatus) => {
    const statusFound = statuses.find((e) => e.value === selectedStatus);
    setSelectedStatus(statusFound);
    if (selectedStatus !== '-') {
      onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'state', selectedStatus);
      resetFilters('state');
    } else {
      onFetchLeads(currentPage, PAGE_LIMIT);
    }
  };

  const handleChangeSearchText = (text) => setSearchedValue(text);

  const fetchLeads = (currentPage) => {
    if (searchedValue === '') {
      onFetchLeads(currentPage, PAGE_LIMIT);
    } else {
      const query = () => {
        if (searchedValue.toLowerCase().includes('$')) {
          return handleReplaceMoneySign(searchedValue);
        } else {
          return searchedValue;
        }
      };
      onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'text', query());
      resetFilters('text');
    }
  };

  const handleReplaceMoneySign = (text) => text.replace(text.includes('$ ') ? '$ ' : '$', '');

  const upToDate = () => {
    if (!endDate) {
      const today = new Date();
      setEndDate(today);
      return today;
    } else {
      return endDate;
    }
  };

  const handleStartDate = (value) => {
    setStartDate(value);

    onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'date', value, upToDate());
    resetFilters('date');
  };

  const handleEndDate = (value) => {
    onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'date', startDate, value);
    resetFilters('date');
    setEndDate(value);
  };

  const handleDeleteLead = (leadId) => setLeadToDelete(leadId);

  const deleteLead = () => {
    onDeleteLead(leadToDelete);
    setLeadToDelete(null);
  };

  const handleDetailLead = (leadId) => {
    const leadToSelect = allLeads.find((lead) => lead.leadId === leadId);
    setSelectedLead(leadToSelect);
  };

  const handleCloseDialog = () => setLeadToDelete(null);

  // useEffect(() => {
  //   if (entities?.length) {
  //     handleEntities();
  //   }
  // }, [entities]);

  useEffect(() => {
    onFetchEntities();
    onFetchLeads(currentPage, PAGE_LIMIT);
  }, []);

  const handleNextPage = (currentPage) => {
    setCurrentPage(currentPage);

    if (selectedStatus.value !== '-') {
      onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'state', selectedStatus.value);
      return;
    }

    if (startDate) {
      onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'date', startDate, upToDate());
      return;
    }

    if (endDate) {
      onFetchFilteredLeads(currentPage, PAGE_LIMIT, 'date', endDate, upToDate());
      return;
    }

    fetchLeads(currentPage);
  };

  const csvRows = allLeads?.leads.map((lead) => {
    return [
      lead.leadId,
      parseToDateTime(lead.createdAt),
      parseToDateTime(lead.updatedAt),
      lead.documentLast || '-',
      lead.amountWanted ? parseToCurrency(lead.amountWanted) : '-',
      lead.installmentsWanted || '-',
      lead.installmentWantedAmount ? parseToCurrency(lead.installmentWantedAmount) : '-',
      lead.acceptedAmount ? parseToCurrency(lead.acceptedAmount) : '-',
      lead.entityId ? getEntityNameById(lead.entityId) : '-',
      +lead.betterOfferAccepted ? 'Si' : 'No',
      lead.betterOfferAmount ? parseToCurrency(lead.betterOfferAmount) : '-',
      lead.betterOfferInstallements || '-',
      lead.betterOfferInstallmentAmount ? parseToCurrency(lead.betterOfferInstallmentAmount) : '-',
      lead.campain || '-',
      lead.source || '-',
      lead.inLeadId,
      lead.state,
    ];
  });

  const csvHeader = [
    'Id',
    'Inicio',
    'Última actualización',
    'Documento',
    'Monto pedido',
    'Cuotas pedidas',
    'Monto de cuota pedida',
    'Monto aceptado',
    'Entidad',
    'Mejor oferta aceptada',
    'Mejor oferta monto',
    'Mejor oferta cuotas',
    'Mejor oferta monto de cuota',
    'Campaña',
    'Origen',
    'CLDC LeadId',
    'Estado',
  ];

  let leads = null;

  if (allLeads) {
    let leadsToShow = allLeads.leads;

    // if (selectedEntity?.value !== 0) {
    //   leadsToShow = leadsToShow.filter((lead) => {
    //     return lead.entityId === selectedEntity.value;
    //   });
    // }

    const amountIcon = (amountWanted, amountGiven) => {
      const title =
        (amountGiven < amountWanted && 'Crédito Otorgado menor a Simulado') ||
        (amountGiven === amountWanted && 'Crédito Otorgado igual a Simulado') ||
        (amountGiven > amountWanted && 'Crédito Otorgado mayor a Simulado');

      const icon =
        (amountGiven < amountWanted && <Minus className={classes.AmountIcon} />) ||
        (amountGiven === amountWanted && <Equal className={classes.AmountIcon} />) ||
        (amountGiven > amountWanted && <Plus className={classes.AmountIcon} />);

      return (
        <Tooltip id="tooltip-top-start" title={title} placement="top">
          {icon}
        </Tooltip>
      );
    };

    const options = leadsToShow
      .sort((firstDate, secondDate) => {
        firstDate = new Date(firstDate.updatedAt);
        secondDate = new Date(secondDate.updatedAt);
        return firstDate >= secondDate ? -1 : 0;
      })

      .map((lead) => {
        return [
          lead.leadId,
          lead.inLeadId || '-',
          <Tooltip
            id="tooltip-top-start"
            title={`Última Actualización ${parseToDateTime(lead.updatedAt)}`}
            placement="top"
          >
            <div> {parseToDateTime(lead.createdAt)}</div>
          </Tooltip>,
          lead.documentLast || '-',
          lead.acceptedAmount
            ? `${parseToCurrency(lead.acceptedAmount)} en ${lead.installmentsGiven} de ${parseToCurrency(
                lead.installmentGivenAmount,
              )}`
            : '-',
          lead.state === 'COMPLETED_APPROVED' || lead.state === 'COMPLETED_REJECTED' ? (
            amountIcon(lead.amountGiven, lead.amountWanted)
          ) : (
            <div className={classes.NotCompleted}>-</div>
          ),
          <LeadState state={lead.state} />,
        ];
      });

    const filter = (
      <div className={classes.FilterContainer}>
        <GridContainer customClass={classes.FilterMainDiv}>
          <GridItem xs={12} className={classes.FilterMainDiv}>
            <GridContainer customClass={classes.FilterMainDiv}>
              <GridItem xs={12} md={4} className={classes.DateInput}>
                <DatePickerInput
                  value={startDate}
                  onChange={handleStartDate}
                  isRequired={false}
                  isValid
                  isTouched={false}
                  label="Fecha desde"
                  placeholder="Fecha desde"
                  smallInput={false}
                  labelClass={classes.label}
                  shrink
                />
              </GridItem>
              <GridItem xs={12} md={4} className={classes.DateInput}>
                <DatePickerInput
                  value={endDate}
                  onChange={handleEndDate}
                  isRequired={false}
                  isValid
                  isTouched={false}
                  label="Fecha hasta"
                  placeholder="Fecha hasta"
                  smallInput={false}
                  labelClass={classes.label}
                  shrink
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <SpecialInput
                  element={{
                    label: 'Estado',
                    elementType: 'select',
                    elementConfig: {
                      variant: 'outlined',
                      options: statuses,
                      mdUpSize: 6,
                    },
                    value: selectedStatus.value,
                  }}
                  onChange={selectStatusHandler}
                />
              </GridItem>
              {/* <GridItem xs={12} md={4}>
                <SpecialInput
                  element={{
                    label: 'Entidad',
                    elementType: 'select',
                    elementConfig: {
                      variant: 'outlined',
                      options: searchableEntities,
                      mdUpSize: 6,
                    },
                    value: selectedEntity.value,
                  }}
                  onChange={selectEntityHandler}
                />
              </GridItem> */}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer customClass={classes.FilterMainDiv} xs={12}>
          <GridItem xs={9} md={10}>
            <SpecialInput
              element={{
                label: 'Buscar..',
                elementType: 'input',
                elementConfig: { placeholder: 'Buscar...', type: 'text' },
                validation: {},
                value: searchedValue,
              }}
              onChange={handleChangeSearchText}
            />
          </GridItem>
          <GridItem xs={3} md={2}>
            <Button
              color="primary"
              size="lg"
              className={classes.SearchButton}
              onClick={() => fetchLeads(currentPage)}
            >
              Buscar
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );

    leads = (
      <TableList
        title={
          <div className={classes.TableTitle}>
            <div>Solicitudes</div>
            <div className={classes.CSVIcon}>
              <CSVDownloader data={[csvHeader, ...csvRows]} />
            </div>
          </div>
        }
        head={['LeadId', 'Inicio', 'Num. Documento', 'Crédito otorgado', '', 'Estado', 'Acciones']}
        filters={filter}
        data={options}
        onDelete={handleDeleteLead}
        delete
        onShowDetail={handleDetailLead}
        detail
        pagination={
          <Pagination
            totalRecords={allLeads.totalRecords}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (loading) {
    leads = <Spinner />;
  }

  return (
    <>
      {leads}
      {selectedLead && <LeadDetail selectedLead={selectedLead} setSelectedLead={setSelectedLead} />}
      <CustomDialog
        title="Confirmar"
        description="¿Estás seguro que deseas eliminar esta Solicitud?"
        open={leadToDelete != null}
        onConfirmation={deleteLead}
        handleClose={handleCloseDialog}
        okButtonText="Eliminar"
        cancelButtonText="Cancelar"
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allLeads: state.leads.leads,
    loading: state.leads.loading,
    entities: state.entities.entities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchFilteredLeads: (page, pageSize, searchType, firstSearchValue, secondSearchValue) =>
    dispatch(actions.filterLeads(page, pageSize, searchType, firstSearchValue, secondSearchValue)),
  onFetchLeads: (page, pageSize) => dispatch(actions.fetchLeads(page, pageSize)),
  onFetchEntities: () => dispatch(actions.fetchEntities()),
  onDeleteLead: (leadId) => dispatch(actions.deleteLead(leadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
